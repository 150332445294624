/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from "react-loader-spinner"
import {
  optionsChannels,
  optionsOperations1,
  optionsOperations2,
  optionsMarcadores,
} from "@components/pageHablemos/components/util"

const ViewTwo = ({
  onClickConfirm,
  numberWorkers,
  numberClient,
  dataOne,
  setSectionView,
  setDataTwo,
  dataTwo,
}) => {
  const valueChannels = productValue => {
    if (
      productValue === "whatsapp_business_api" ||
      productValue === "whatsapp_broadcast"
    ) {
      // setSelectedOptionsChannel(["voz"])
      return ["whatsapp"]
    } else if (productValue === "marcador_predictivo") {
      // setSelectedOptionsChannel(["whatsapp"])
      return ["voz"]
    } else {
      return []
    }
  }
  console.log(dataOne.products )

  const [selectedOptionsChannel, setSelectedOptionsChannel] = useState(
    valueChannels(dataOne.products)
  )

  const handleOptionChannelChange = option => {
    const isSelected = selectedOptionsChannel.includes(option.id)

    if (isSelected) {
      // Si ya está seleccionado, quitarlo del array
      setSelectedOptionsChannel(prevState =>
        prevState.filter(id => id !== option.id)
      )
    } else {
      // Si no está seleccionado, agregarlo al array
      setSelectedOptionsChannel(prevState => [...prevState, option.id])
    }
  }

  const [selectedOptionsOperations, setSelectedOptionsOperations] = useState(
    dataTwo.operations ? dataTwo.operations : []
  )

  const handleOptionOperationsChange = option => {
    const isSelected = selectedOptionsOperations.includes(option.id)

    if (isSelected) {
      // Si ya está seleccionado, quitarlo del array
      setSelectedOptionsOperations(prevState =>
        prevState.filter(id => id !== option.id)
      )
    } else {
      // Si no está seleccionado, agregarlo al array
      setSelectedOptionsOperations(prevState => [...prevState, option.id])
      setValues({ ...values, operations: selectedOptionsOperations })
    }
  }

  const [selectedOptionsMarcadores, setSelectedOptionsMarcadores] = useState(
    dataTwo.marcadores ? dataTwo.marcadores : []
  )

  const handleOptionMarcadoresChange = option => {
    const isSelected = selectedOptionsMarcadores.includes(option.id)

    if (isSelected) {
      // Si ya está seleccionado, quitarlo del array
      setSelectedOptionsMarcadores(prevState =>
        prevState.filter(id => id !== option.id)
      )
    } else {
      // Si no está seleccionado, agregarlo al array
      setSelectedOptionsMarcadores(prevState => [...prevState, option.id])
      setValues({ ...values, marcadores: selectedOptionsMarcadores })
    }
  }

  //Estado para mostrar el spin de cargando en boton enviar
  const [spin, setSpin] = useState(false)

  //Estado valores inputs
  const [values, setValues] = useState({
    channels: valueChannels(dataOne.products),
    workers: dataTwo.workers ? dataTwo.workers : "default",
    providerVoip: dataTwo.providerVoip ? dataTwo.providerVoip : "default",
    whatsappApi: dataTwo.whatsappApi ? dataTwo.whatsappApi : "default",
    clients: dataTwo.clients ? dataTwo.clients : "default",
    description: dataTwo.description ? dataTwo.description : "",
    operations: dataTwo.operations ? dataTwo.operations : [],
    marcadores: dataTwo.marcadores ? dataTwo.marcadores : [],
    softwareContact: dataTwo.softwareContact
      ? dataTwo.softwareContact
      : "default",
  })

  useEffect(() => {
    setValues({ ...values, channels: selectedOptionsChannel })
  }, [selectedOptionsChannel])

  useEffect(() => {
    setDataTwo(values)
  }, [values])

  useEffect(() => {
    setValues({ ...values, operations: selectedOptionsOperations })
  }, [selectedOptionsOperations])

  useEffect(() => {
    setValues({ ...values, marcadores: selectedOptionsMarcadores })
  }, [selectedOptionsMarcadores])

  const [blogChecked, setBlogChecked] = useState(false)

  const {
    channels,
    description,
    workers,
    providerVoip,
    whatsappApi,
    clients,
    operations,
    marcadores,
    softwareContact,
  } = values

  //Estado validaciones
  const [validations, setValidations] = useState({
    channels: "",
    workers: "",
    providerVoip: "",
    whatsappApi: "",
    clients: "",
    description: "",
    operations: "",
    marcadores: "",
    softwareContact: "",
  })

  //Validar todos los campos que no esten vacios y en el formato correcto al presionar enviar
  const validateAll = () => {
    const {
      channels,
      workers,
      providerVoip,
      whatsappApi,
      clients,
      description,
      operations,
      marcadores,
      softwareContact,
    } = values

    const validations = {
      channels: "",
      workers: "",
      providerVoip: "",
      whatsappApi: "",
      clients: "",
      description: "",
      operations: "",
      marcadores: "",
      softwareContact: "",
    }
    let isValid = true
    if (!channels) {
      validations.channels = "Campo requerido"
      isValid = false
    }
    if (workers === "default") {
      validations.workers = "Campo requerido"
      isValid = false
    }
    if (clients === "default") {
      validations.clients = "Campo requerido"
      isValid = false
    }
    if (!description) {
      validations.description = "Campo requerido"
      isValid = false
    }
    if (operations.length < 1) {
      validations.operations = "Campo requerido"
      isValid = false
    }
    if (marcadores.length < 1) {
      if (channels.includes("voz")) {
        validations.marcadores = "Campo requerido"
        isValid = false
      }
    }
    if (softwareContact === "default") {
      validations.softwareContact = "Campo requerido"
      isValid = false
    }
    if (whatsappApi === "default") {
      if (channels.includes("whatsapp")) {
        validations.whatsappApi = "Campo requerido"
        isValid = false
      }
    }
    if (providerVoip === "default") {
      if (channels.includes("voz")) {
        validations.providerVoip = "Campo requerido"
        isValid = false
      }
    }
    if (!isValid) {
      setValidations(validations)
    }

    return isValid
  }

  //Validar campos mientras el usuario escribe
  const validateOne = e => {
    const { name } = e.target
    const value = values[name]
    let message = ""

    if (!value) {
      message = `Campo requerido`
    }
    setValidations({ ...validations, [name]: message })
  }

  //Obtener y guardar valores de los inputs
  const handleChange = e => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const {
    channels: channelsVal,
    description: descriptionVal,
    workers: workersVal,
    providerVoip: providerVoipVal,
    whatsappApi: whatsappApiVal,
    clients: clientsVal,
    operations: operationsVal,
    marcadores: marcadoresVal,
    softwareContact: softwareContactVal,
  } = validations

  //Funcion envio formulario
  const handleSubmitTwo = async () => {
    const isValid = validateAll()

    //Validar los campos
    if (isValid) {
      setSpin(true)
      setTimeout(() => {
        setSpin(false)
        onClickConfirm(true)
      }, 5000)
      try {
        const params = new URLSearchParams()
        params.append("landing", "Hablemos") // Esto debe ser "Hablemos"
        params.append("firstname", dataOne.name)
        params.append("lastname", dataOne.lastname)
        params.append("email", dataOne.email)
        params.append("company", dataOne.company)
        params.append("jobtitle", dataOne.jobtitle)
        params.append("website", dataOne.website)
        params.append("codeCountry", dataOne.codeCountry.code)
        params.append("phone", dataOne.phone)
        params.append("country", dataOne.pais.label)
        params.append("product", dataOne.products)
        params.append("channels", values.channels)
        params.append("providerVoip", values.providerVoip)
        params.append("whatsappApi", values.whatsappApi)
        params.append("clients", values.clients) // Valores permitidos: '0-500','500-2000','2000 a más'
        params.append("description", values.description)
        params.append("operations", values.operations)
        params.append("marcadores", values.marcadores)
        params.append("softwareContact", values.softwareContact)
        params.append("workers", values.workers) // Valores permitidos: '1-4','5-10','11-20','21-30','40 a más'
        params.append("blogChecked", blogChecked)

        await fetch(`/backend/contactus.php`, {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
          },
          body: params,
        })
      } catch (error) {
        console.log(error)
      }

      setValues({
        channels: "",
        workers: "default",
        providerVoip: "default",
        whatsappApi: "default",
        clients: "default",
        description: "",
        operations: "",
        marcadores: "",
        softwareContact: "default",
      })
    } else {
      return false
    }
  }

  const renderOptions = options =>
    options.map(option => (
      <div
        key={option.id}
        className={`container-page-hablemos-form-container_checkbox-channels-card ${
          selectedOptionsOperations.includes(option.id) ? "selected" : ""
        }`}
      >
        <input
          type="checkbox"
          value={operations}
          id={`operation${option.id}`}
          checked={selectedOptionsOperations.includes(option.id)}
          onChange={() => handleOptionOperationsChange(option)}
        />
        <label htmlFor={`operation${option.id}`}>
          <div className="container-page-hablemos-form-container_checkbox-channels-card-info">
            <img src={option.image} alt={option.title} />
            <div>
              <p>{option.description}</p>
            </div>
          </div>
        </label>
      </div>
    ))

  return (
    <div className={"container-page-hablemos-form"}>
      <hr className="container-page-hablemos-form-blueTotal" />
      <div className="container-page-hablemos-form-form">
        <div className="container-page-hablemos-form-title">
          <p className="container-page-hablemos-form-title_title">
            Cuéntanos sobre tu caso de uso
          </p>
          {dataOne.products === "score_v2" ||
          dataOne.products === "marcador_predictivo" ? (
            <p>
              Notamos que tu interés pasa por nuestras plataformas de Contact
              Center o soluciones de voz. Por favor, cuéntanos un poco más de tu
              necesidad. 🤓
            </p>
          ) : (
            <p>
              Notamos que tu interés pasa por nuestras soluciones omnicanales o
              de mensajería. Por favor, cuéntanos un poco más de tu necesidad.
              🤓
            </p>
          )}
        </div>
        <div className="container-page-hablemos-form-container_checkbox">
          <p className="container-page-hablemos-form-inputContainer_label">
            ¿Cuáles canales tienes planeado usar?
          </p>
          <div className="container-page-hablemos-form-container_checkbox-channels">
            {optionsChannels.map(option => (
              <div
                key={option.id}
                className={`container-page-hablemos-form-container_checkbox-channels-card  ${
                  selectedOptionsChannel.includes(option.id) ? "selected" : ""
                }`}
              >
                <input
                  type="checkbox"
                  value={channels}
                  id={`channel${option.id}`}
                  checked={selectedOptionsChannel.includes(option.id)}
                  onChange={() => handleOptionChannelChange(option)}
                />
                <label htmlFor={`channel${option.id}`}>
                  <div className="container-page-hablemos-form-container_checkbox-channels-card-info">
                    <img src={option.image} alt={option.title} />
                    <div>
                      <p>{option.description}</p>
                    </div>
                  </div>
                </label>
              </div>
            ))}
          </div>
          <br />
          <div className="error">{channelsVal}</div>
        </div>

        {selectedOptionsChannel.includes("voz") ? (
          <>
            <div className="container-page-hablemos-form-container_checkbox">
              <p className="container-page-hablemos-form-inputContainer_label">
                ¿Cuáles marcaciones telefónicas deseas implementar?
              </p>
              <div className="container-page-hablemos-form-container_checkbox-channels">
                {optionsMarcadores.map(option => (
                  <div
                    key={option.id}
                    className={`container-page-hablemos-form-container_checkbox-channels-card ${
                      selectedOptionsMarcadores.includes(option.id)
                        ? "selected"
                        : ""
                    }`}
                  >
                    <input
                      type="checkbox"
                      id={`marcador${option.id}`}
                      value={marcadores}
                      checked={selectedOptionsMarcadores.includes(option.id)}
                      onChange={() => handleOptionMarcadoresChange(option)}
                    />
                    <label htmlFor={`marcador${option.id}`}>
                      <div className="container-page-hablemos-form-container_checkbox-channels-card-info">
                        <img src={option.image} alt={option.title} />
                        <div>
                          <p>{option.description}</p>
                        </div>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
              <br />
              <div className="error">{marcadoresVal}</div>
            </div>
            <div className="container-page-hablemos-form-inputContainer">
              <div style={{ position: "relative", marginTop: "12px" }}>
                <p className="container-page-hablemos-form-inputContainer_label">
                  ¿Cuentas actualmente con un proveedor de telefonía Voip?
                </p>
                <select
                  className={
                    providerVoip === "default"
                      ? "container-page-hablemos-form-inputContainer_inputOne select-default "
                      : "container-page-hablemos-form-inputContainer_inputOne"
                  }
                  type="text"
                  name="providerVoip"
                  value={providerVoip}
                  onChange={handleChange}
                  defaultValue={providerVoip}
                >
                  <option value="default" disabled hidden>
                    Seleccionar
                  </option>
                  <option key={1} value={"si"}>
                    Si
                  </option>
                  <option key={2} value={"no"}>
                    No
                  </option>
                </select>
                <i></i>
                <div className="error">{providerVoipVal}</div>
              </div>
            </div>
          </>
        ) : null}

        {selectedOptionsChannel.includes("whatsapp") ? (
          <div className="container-page-hablemos-form-inputContainer">
            <div style={{ position: "relative", marginTop: "12px" }}>
              <p className="container-page-hablemos-form-inputContainer_label">
                ¿Actualmente trabajan con la API de WhatsApp Business?
              </p>
              <select
                className={
                  whatsappApi === "default"
                    ? "container-page-hablemos-form-inputContainer_inputOne select-default"
                    : "container-page-hablemos-form-inputContainer_inputOne"
                }
                type="text"
                name="whatsappApi"
                value={whatsappApi}
                onChange={handleChange}
                defaultValue={whatsappApi}
              >
                <option value="default" disabled hidden>
                  Seleccionar
                </option>

                <option key={1} value={"si"}>
                  Si
                </option>
                <option key={2} value={"no"}>
                  No
                </option>
              </select>
              <i></i>
              <div className="error">{whatsappApiVal}</div>
            </div>
          </div>
        ) : null}

        <div className="container-page-hablemos-form-inputContainer">
          <div style={{ position: "relative", marginTop: "12px" }}>
            <p className="container-page-hablemos-form-inputContainer_label">
              ¿Con cuántos clientes contactas o hablas al mes?
            </p>
            <select
              className={
                clients === "default"
                  ? "  container-page-hablemos-form-inputContainer_inputOne select-default "
                  : "container-page-hablemos-form-inputContainer_inputOne"
              }
              type="text"
              name="clients"
              value={clients}
              onChange={handleChange}
              onBlur={validateOne}
              defaultValue={clients}
            >
              <option value="default" disabled hidden>
                Seleccionar
              </option>
              {numberClient.map((element, index) => {
                return (
                  <option key={index} value={element}>
                    {element}
                  </option>
                )
              })}
            </select>
            <i></i>
            <div className="error">{clientsVal}</div>
          </div>
        </div>
        <div className="container-page-hablemos-form-inputContainer">
          <div style={{ position: "relative" }}>
            <p className="container-page-hablemos-form-inputContainer_label">
              ¿Cuántos agentes usarán la plataforma?
            </p>
            <select
              className={
                workers === "default"
                  ? "  container-page-hablemos-form-inputContainer_inputOne select-default "
                  : "container-page-hablemos-form-inputContainer_inputOne"
              }
              type="text"
              name="workers"
              value={workers}
              onChange={handleChange}
              onBlur={validateOne}
              defaultValue={workers}
            >
              <option value="default" disabled hidden>
                Seleccionar
              </option>
              {numberWorkers.map((element, index) => {
                return (
                  <option key={index} value={element}>
                    {element}
                  </option>
                )
              })}
            </select>
            <i></i>
            <div className="error">{workersVal}</div>
          </div>
        </div>
        <div className="container-page-hablemos-form-container_checkbox">
          <p className="container-page-hablemos-form-inputContainer_label">
            ¿Cuál tipo de operación o caso de uso buscas mejorar?
          </p>
          <div className="container-page-hablemos-form-container_checkbox-channels">
            {dataOne.products === "ticker" ||
            dataOne.products === "whatsapp_business_api" ||
            dataOne.products === "chatbots" ||
            dataOne.products === "whatsapp_broadcast"
              ? renderOptions(optionsOperations1)
              : renderOptions(optionsOperations2)}
          </div>
          <br />
          <div className="error">{operationsVal}</div>
        </div>
        <div className="container-page-hablemos-form-inputContainer">
          <div style={{ position: "relative", marginTop: "12px" }}>
            <p className="container-page-hablemos-form-inputContainer_label">
              ¿Actualmente trabajan con algún software de Contact Center?
            </p>
            <select
              className={
                softwareContact === "default"
                  ? "  container-page-hablemos-form-inputContainer_inputOne select-default "
                  : "container-page-hablemos-form-inputContainer_inputOne"
              }
              type="text"
              name="softwareContact"
              value={softwareContact}
              onChange={handleChange}
              onBlur={validateOne}
              defaultValue={softwareContact}
            >
              <option value="default" disabled hidden>
                Seleccionar
              </option>
              <option key={"si"} value={"si"}>
                Si
              </option>
              <option key={"no"} value={"no"}>
                No
              </option>
            </select>
            <i></i>
            <div className="error">{softwareContactVal}</div>
          </div>
        </div>
        <div className="container-page-hablemos-form-textareaContainer">
          <div>
            <p className="container-page-hablemos-form-textareaContainer_label">
              Por último, cuéntanos, ¿cómo deseas que te ayuden nuestras
              soluciones?
            </p>
            <textarea
              className="container-page-hablemos-form-textareaContainer_input"
              placeholder="Escribir aquí...."
              type="text"
              name="description"
              value={description}
              onChange={handleChange}
              onBlur={validateOne}
              defaultValue={description}
            />
            <div className="error">{descriptionVal}</div>
          </div>
        </div>
        <div className="container-page-hablemos-form-checkox-demo">
          <input
            type="checkbox"
            value={blogChecked}
            onChange={() => setBlogChecked(!blogChecked)}
            defaultChecked={blogChecked}
          />
          <div>
            <p className="container-page-hablemos-form-checkox-demo-strong">
              Deseo recibir artículos del blog de Beex 😍
            </p>
            <p className="container-page-hablemos-form-checkox-demo-description">
              Prometemos solo enviarte información relevante. Nada de spam ni
              información innecesaria.{" "}
            </p>
          </div>
        </div>
        <div
          style={{
            marginTop: "31px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            className="container-page-hablemos-form-btnSecondary"
            onClick={() => setSectionView(true)}
          >
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.0303 18.5303C11.7374 18.8232 11.2626 18.8232 10.9697 18.5303L4.96967 12.5303C4.67678 12.2374 4.67678 11.7626 4.96967 11.4697L10.9697 5.46967C11.2626 5.17678 11.7374 5.17678 12.0303 5.46967C12.3232 5.76256 12.3232 6.23744 12.0303 6.53033L7.31066 11.25L19.5 11.25C19.9142 11.25 20.25 11.5858 20.25 12C20.25 12.4142 19.9142 12.75 19.5 12.75L7.31066 12.75L12.0303 17.4697C12.3232 17.7626 12.3232 18.2374 12.0303 18.5303Z"
                fill="#262A31"
              />
            </svg>{" "}
            Volver
          </button>
          <button
            className="container-page-hablemos-form-btnPrimary"
            onClick={e => handleSubmitTwo(e)}
          >
            Enviar{" "}
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4697 5.46967C12.7626 5.17678 13.2374 5.17678 13.5303 5.46967L19.5303 11.4697C19.8232 11.7626 19.8232 12.2374 19.5303 12.5303L13.5303 18.5303C13.2374 18.8232 12.7626 18.8232 12.4697 18.5303C12.1768 18.2374 12.1768 17.7626 12.4697 17.4697L17.1893 12.75H5C4.58579 12.75 4.25 12.4142 4.25 12C4.25 11.5858 4.58579 11.25 5 11.25H17.1893L12.4697 6.53033C12.1768 6.23744 12.1768 5.76256 12.4697 5.46967Z"
                fill="#262A31"
              />
            </svg>
            {spin ? (
              <Loader type="Oval" color="#5e5e5e" height={20} width={20} />
            ) : (
              ""
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ViewTwo
