/* eslint-disable no-undef */
import React, { useState } from "react"
import dataOne from "@components/pageHome/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import Intro from "@components/pageHablemos/components/intro"
import TemplatePagesThank from "@components/pageThanks/pageThanksGeneral/template"

const TemplatePageHablemos = ({ location, data }) => {

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  //Array paises
  const countries = [
    {
      value: "Afganistán",
      label: "Afganistán",
      code: "+93",
      image: "https://flagcdn.com/w320/af.png",
    },
    {
      value: "Albania",
      label: "Albania",
      code: "+355",
      image: "https://flagcdn.com/w320/al.png",
    },
    {
      value: "Argelia",
      label: "Argelia",
      code: "+213",
      image: "https://flagcdn.com/w320/dz.png",
    },
    {
      value: "Samoa Americana",
      label: "Samoa Americana",
      code: "+1 684",
      image: "https://flagcdn.com/w320/as.png",
    },
    {
      value: "Andorra",
      label: "Andorra",
      code: "+376",
      image: "https://flagcdn.com/w320/ad.png",
    },
    {
      value: "Angola",
      label: "Angola",
      code: "+244",
      image: "https://flagcdn.com/w320/ao.png",
    },
    {
      value: "Anguila",
      label: "Anguila",
      code: "+1 264",
      image: "https://flagcdn.com/w320/ai.png",
    },
    {
      value: "Antigua y Barbuda",
      label: "Antigua y Barbuda",
      code: "+1 268",
      image: "https://flagcdn.com/w320/ag.png",
    },
    {
      value: "Argentina",
      label: "Argentina",
      code: "+54",
      image: "https://flagcdn.com/w320/ar.png",
    },
    {
      value: "Armenia",
      label: "Armenia",
      code: "+374",
      image: "https://flagcdn.com/w320/am.png",
    },
    {
      value: "Aruba",
      label: "Aruba",
      code: "+297",
      image: "https://flagcdn.com/w320/aw.png",
    },
    {
      value: "Australia",
      label: "Australia",
      code: "+61",
      image: "https://flagcdn.com/w320/au.png",
    },
    {
      value: "Austria",
      label: "Austria",
      code: "+43",
      image: "https://flagcdn.com/w320/at.png",
    },
    {
      value: "Azerbaiyán",
      label: "Azerbaiyán",
      code: "+994",
      image: "https://flagcdn.com/w320/az.png",
    },
    {
      value: "Bahamas",
      label: "Bahamas",
      code: "+1 242",
      image: "https://flagcdn.com/w320/bs.png",
    },
    {
      value: "Baréin",
      label: "Baréin",
      code: "+973",
      image: "https://flagcdn.com/w320/bh.png",
    },
    {
      value: "Bangladesh",
      label: "Bangladesh",
      code: "+880",
      image: "https://flagcdn.com/w320/bd.png",
    },
    {
      value: "Barbados",
      label: "Barbados",
      code: "+1 246",
      image: "https://flagcdn.com/w320/bb.png",
    },
    {
      value: "Belarus",
      label: "Belarus",
      code: "+375",
      image: "https://flagcdn.com/w320/by.png",
    },
    {
      value: "Bélgica",
      label: "Bélgica",
      code: "+32",
      image: "https://flagcdn.com/w320/be.png",
    },
    {
      value: "Belice",
      label: "Belice",
      code: "+501",
      image: "https://flagcdn.com/w320/bz.png",
    },
    {
      value: "Benín",
      label: "Benín",
      code: "+229",
      image: "https://flagcdn.com/w320/bj.png",
    },
    {
      value: "Bermuda",
      label: "Bermuda",
      code: "+1 441",
      image: "https://flagcdn.com/w320/bm.png",
    },
    {
      value: "Bután",
      label: "Bután",
      code: "+975",
      image: "https://flagcdn.com/w320/bt.png",
    },
    {
      value: "Bolivia",
      label: "Bolivia",
      code: "+591",
      image: "https://flagcdn.com/w320/bo.png",
    },
    {
      value: "Bosnia/Herzegovina",
      label: "Bosnia/Herzegovina",
      code: "+387",
      image: "https://flagcdn.com/w320/ba.png",
    },
    {
      value: "Botsuana",
      label: "Botsuana",
      code: "+267",
      image: "https://flagcdn.com/w320/bw.png",
    },
    {
      value: "Brasil",
      label: "Brasil",
      code: "+55",
      image: "https://flagcdn.com/w320/br.png",
    },
    {
      value: "Islas Vírgenes Británicas",
      label: "Islas Vírgenes Británicas",
      code: "+1 284",
      image: "https://flagcdn.com/w320/vg.png",
    },
    {
      value: "Brunéi",
      label: "Brunéi",
      code: "+673",
      image: "https://flagcdn.com/w320/bn.png",
    },
    {
      value: "Bulgaria",
      label: "Bulgaria",
      code: "+359",
      image: "https://flagcdn.com/w320/bg.png",
    },
    {
      value: "Burkina Faso",
      label: "Burkina Faso",
      code: "+226",
      image: "https://flagcdn.com/w320/bf.png",
    },
    {
      value: "Burundi",
      label: "Burundi",
      code: "+257",
      image: "https://flagcdn.com/w320/bi.png",
    },
    {
      value: "Camboya",
      label: "Camboya",
      code: "+855",
      image: "https://flagcdn.com/w320/kh.png",
    },
    {
      value: "Camerún",
      label: "Camerún",
      code: "+237",
      image: "https://flagcdn.com/w320/cm.png",
    },
    {
      value: "Islas de Cabo Verde",
      label: "Islas de Cabo Verde",
      code: "+238",
      image: "https://flagcdn.com/w320/cv.png",
    },
    {
      value: "Islas Caimán",
      label: "Islas Caimán",
      code: "+1 345",
      image: "https://flagcdn.com/w320/ky.png",
    },
    {
      value: "República Centroafricana",
      label: "República Centroafricana",
      code: "+236",
      image: "https://flagcdn.com/w320/cf.png",
    },
    {
      value: "Chad",
      label: "Chad",
      code: "+235",
      image: "https://flagcdn.com/w320/td.png",
    },
    {
      value: "Chile",
      label: "Chile",
      code: "+56",
      image: "https://flagcdn.com/w320/cl.png",
    },
    {
      value: "China",
      label: "China",
      code: "+86",
      image: "https://flagcdn.com/w320/cn.png",
    },
    {
      value: "Colombia",
      label: "Colombia",
      code: "+57",
      image: "https://flagcdn.com/w320/co.png",
    },
    {
      value: "Comoras",
      label: "Comoras",
      code: "+269",
      image: "https://flagcdn.com/w320/km.png",
    },
    {
      value: "Congo (DROC)",
      label: "Congo (DROC)",
      code: "+243",
      image: "https://flagcdn.com/w320/cg.png",
    },
    {
      value: "Congo (ROC)",
      label: "Congo (ROC)",
      code: "+242",
      image: "https://flagcdn.com/w320/cd.png",
    },
    {
      value: "Islas Cook",
      label: "Islas Cook",
      code: "+682",
      image: "https://flagcdn.com/w320/ck.png",
    },
    {
      value: "Costa Rica",
      label: "Costa Rica",
      code: "+506",
      image: "https://flagcdn.com/w320/cr.png",
    },
    {
      value: "Croacia",
      label: "Croacia",
      code: "+385",
      image: "https://flagcdn.com/w320/hr.png",
    },
    {
      value: "Cuba",
      label: "Cuba",
      code: "+53",
      image: "https://flagcdn.com/w320/cu.png",
    },
    {
      value: "Chipre",
      label: "Chipre",
      code: "+357",
      image: "https://flagcdn.com/w320/cy.png",
    },
    {
      value: "República Checa",
      label: "República Checa",
      code: "+420",
      image: "https://flagcdn.com/w320/cz.png",
    },
    {
      value: "Dinamarca",
      label: "Dinamarca",
      code: "+45",
      image: "https://flagcdn.com/w320/dk.png",
    },
    {
      value: "Diego García",
      label: "Diego García",
      code: "+246",
      image: "https://flagcdn.com/w320/io.png",
    },
    {
      value: "Yibuti",
      label: "Yibuti",
      code: "+253",
      image: "https://flagcdn.com/w320/dj.png",
    },
    {
      value: "Dominica",
      label: "Dominica",
      code: "+1 (767)",
      image: "https://flagcdn.com/w320/dm.png",
    },
    {
      value: "República Dominicana",
      label: "República Dominicana",
      code: "+1 809",
      image: "https://flagcdn.com/w320/do.png",
    },
    {
      value: "República Dominicana",
      label: "República Dominicana",
      code: "+1 829",
      image: "https://flagcdn.com/w320/do.png",
    },
    {
      value: "República Dominicana",
      label: "República Dominicana",
      code: "+1 849",
      image: "https://flagcdn.com/w320/do.png",
    },
    {
      value: "Ecuador",
      label: "Ecuador",
      code: "+593",
      image: "https://flagcdn.com/w320/ec.png",
    },
    {
      value: "Egipto",
      label: "Egipto",
      code: "+20",
      image: "https://flagcdn.com/w320/eg.png",
    },
    {
      value: "El Salvador",
      label: "El Salvador",
      code: "+503",
      image: "https://flagcdn.com/w320/sv.png",
    },
    {
      value: "Guinea Ecuatorial",
      label: "Guinea Ecuatorial",
      code: "+240",
      image: "https://flagcdn.com/w320/gq.png",
    },
    {
      value: "Eritrea",
      label: "Eritrea",
      code: "+291",
      image: "https://flagcdn.com/w320/er.png",
    },
    {
      value: "Estonia",
      label: "Estonia",
      code: "+372",
      image: "https://flagcdn.com/w320/ee.png",
    },
    {
      value: "Etiopía",
      label: "Etiopía",
      code: "+251",
      image: "https://flagcdn.com/w320/et.png",
    },
    {
      value: "Islas Malvinas",
      label: "Islas Malvinas",
      code: "+500",
      image: "https://flagcdn.com/w320/fk.png",
    },
    {
      value: "Islas Feroe",
      label: "Islas Feroe",
      code: "+298",
      image: "https://flagcdn.com/w320/fo.png",
    },
    {
      value: "Fiyi",
      label: "Fiyi",
      code: "+679",
      image: "https://flagcdn.com/w320/fj.png",
    },
    {
      value: "Finlandia",
      label: "Finlandia",
      code: "+358",
      image: "https://flagcdn.com/w320/fi.png",
    },
    {
      value: "Francia",
      label: "Francia",
      code: "+33",
      image: "https://flagcdn.com/w320/fr.png",
    },
    {
      value: "Guyana Francesa",
      label: "Guyana Francesa",
      code: "+594",
      image: "https://flagcdn.com/w320/gf.png",
    },
    {
      value: "Gabón",
      label: "Gabón",
      code: "+241",
      image: "https://flagcdn.com/w320/ga.png",
    },
    {
      value: "Gambia",
      label: "Gambia",
      code: "+220",
      image: "https://flagcdn.com/w320/gm.png",
    },
    {
      value: "Georgia",
      label: "Georgia",
      code: "+995",
      image: "https://flagcdn.com/w320/ge.png",
    },
    {
      value: "Alemania",
      label: "Alemania",
      code: "+49",
      image: "https://flagcdn.com/w320/de.png",
    },
    {
      value: "Ghana",
      label: "Ghana",
      code: "+233",
      image: "https://flagcdn.com/w320/gh.png",
    },
    {
      value: "Gibraltar",
      label: "Gibraltar",
      code: "+350",
      image: "https://flagcdn.com/w320/gi.png",
    },
    {
      value: "Grecia",
      label: "Grecia",
      code: "+30",
      image: "https://flagcdn.com/w320/gr.png",
    },
    {
      value: "Groenlandia",
      label: "Groenlandia",
      code: "+299",
      image: "https://flagcdn.com/w320/gl.png",
    },
    {
      value: "Granada",
      label: "Granada",
      code: "+1 473",
      image: "https://flagcdn.com/w320/gd.png",
    },
    {
      value: "Guadalupe (Antillas Francesas)",
      label: "Guadalupe (Antillas Francesas)",
      code: "+590",
      image: "https://flagcdn.com/w320/gp.png",
    },
    {
      value: "Guatemala",
      label: "Guatemala",
      code: "+502",
      image: "https://flagcdn.com/w320/gt.png",
    },
    {
      value: "Guernsey",
      label: "Guernsey",
      code: "+44",
      image: "https://flagcdn.com/w320/gg.png",
    },
    {
      value: "Guinea",
      label: "Guinea",
      code: "+224",
      image: "https://flagcdn.com/w320/gn.png",
    },
    {
      value: "Guinea-Bisáu",
      label: "Guinea-Bisáu",
      code: "+245",
      image: "https://flagcdn.com/w320/gw.png",
    },
    {
      value: "Guyana",
      label: "Guyana",
      code: "+592",
      image: "https://flagcdn.com/w320/gy.png",
    },
    {
      value: "Haití",
      label: "Haití",
      code: "+509",
      image: "https://flagcdn.com/w320/ht.png",
    },
    {
      value: "Honduras",
      label: "Honduras",
      code: "+504",
      image: "https://flagcdn.com/w320/hn.png",
    },
    {
      value: "Hong Kong",
      label: "Hong Kong",
      code: "+852",
      image: "https://flagcdn.com/w320/hk.png",
    },
    {
      value: "Hungría",
      label: "Hungría",
      code: "+36",
      image: "https://flagcdn.com/w320/hu.png",
    },
    {
      value: "Islandia",
      label: "Islandia",
      code: "+354",
      image: "https://flagcdn.com/w320/is.png",
    },
    {
      value: "India",
      label: "India",
      code: "+91",
      image: "https://flagcdn.com/w320/in.png",
    },
    {
      value: "Indonesia",
      label: "Indonesia",
      code: "+62",
      image: "https://flagcdn.com/w320/id.png",
    },
    {
      value: "Irán",
      label: "Irán",
      code: "+98",
      image: "https://flagcdn.com/w320/ir.png",
    },
    {
      value: "Irak",
      label: "Irak",
      image: "https://flagcdn.com/w320/iq.png",
      code: "+964",
    },
    {
      value: "Irlanda",
      label: "Irlanda",
      image: "https://flagcdn.com/w320/ie.png",
      code: "+353",
    },
    {
      value: "Isla de Man",
      label: "Isla de Man",
      image: "https://flagcdn.com/w320/im.png",
      code: "+44",
    },
    {
      value: "Israel",
      label: "Israel",
      image: "https://flagcdn.com/w320/il.png",
      code: "+972",
    },
    {
      value: "Italia",
      label: "Italia",
      image: "https://flagcdn.com/w320/it.png",
      code: "+39",
    },
    {
      value: "Costa de Marfil",
      label: "Costa de Marfil",
      image: "https://flagcdn.com/w320/ci.png",
      code: "+225",
    },
    {
      value: "Jamaica",
      label: "Jamaica",
      image: "https://flagcdn.com/w320/jm.png",
      code: "+1 876",
    },
    {
      value: "Japón",
      label: "Japón",
      image: "https://flagcdn.com/w320/jp.png",
      code: "+81",
    },
    {
      value: "Jersey",
      label: "Jersey",
      image: "https://flagcdn.com/w320/je.png",
      code: "+44",
    },
    {
      value: "Jordania",
      label: "Jordania",
      image: "https://flagcdn.com/w320/jo.png",
      code: "+962",
    },
    {
      value: "Kazajistán",
      label: "Kazajistán",
      image: "https://flagcdn.com/w320/kz.png",
      code: "+7",
    },
    {
      value: "Kenia",
      label: "Kenia",
      image: "https://flagcdn.com/w320/ke.png",
      code: "+254",
    },
    {
      value: "Kiribati",
      label: "Kiribati",
      image: "https://flagcdn.com/w320/ki.png",
      code: "+686",
    },
    {
      value: "Corea (Norte)",
      label: "Corea (Norte)",
      image: "https://flagcdn.com/w320/kp.png",
      code: "+850",
    },
    {
      value: "Corea (Sur)",
      label: "Corea (Sur)",
      image: "https://flagcdn.com/w320/kr.png",
      code: " +82",
    },
    {
      value: "Kuwait",
      label: "Kuwait",
      image: "https://flagcdn.com/w320/kw.png",
      code: "+965",
    },
    {
      value: "Kirguizistán",
      label: "Kirguizistán",
      image: "https://flagcdn.com/w320/kg.png",
      code: "+996",
    },
    {
      value: "Laos",
      label: "Laos",
      image: "https://flagcdn.com/w320/la.png",
      code: "+856",
    },
    {
      value: "Letonia",
      label: "Letonia",
      image: "https://flagcdn.com/w320/lv.png",
      code: "+371",
    },
    {
      value: "Líbano",
      label: "Líbano",
      image: "https://flagcdn.com/w320/lb.png",
      code: "+961",
    },
    {
      value: "Lesoto",
      label: "Lesoto",
      image: "https://flagcdn.com/w320/ls.png",
      code: "+266",
    },
    {
      value: "Liberia",
      label: "Liberia",
      image: "https://flagcdn.com/w320/lr.png",
      code: "+231",
    },
    {
      value: "Libia",
      label: "Libia",
      image: "https://flagcdn.com/w320/ly.png",
      code: "+218",
    },
    {
      value: "Liechtenstein",
      label: "Liechtenstein",
      image: "https://flagcdn.com/w320/li.png",
      code: "+423",
    },
    {
      value: "Lituania",
      label: "Lituania",
      image: "https://flagcdn.com/w320/lt.png",
      code: "+370",
    },
    {
      value: "Luxemburgo",
      label: "Luxemburgo",
      image: "https://flagcdn.com/w320/lu.png",
      code: "+352",
    },
    {
      value: "Macau",
      label: "Macau",
      image: "https://flagcdn.com/w320/mo.png",
      code: "+853",
    },
    {
      value: "Macedonia",
      label: "Macedonia",
      image: "https://flagcdn.com/w320/mk.png",
      code: "+389",
    },
    {
      value: "Madagascar",
      label: "Madagascar",
      image: "https://flagcdn.com/w320/mg.png",
      code: "+261",
    },
    {
      value: "Malaui",
      label: "Malaui",
      image: "https://flagcdn.com/w320/mw.png",
      code: "+265",
    },
    {
      value: "Malasia",
      label: "Malasia",
      image: "https://flagcdn.com/w320/my.png",
      code: "+60",
    },
    {
      value: "Maldivas",
      label: "Maldivas",
      image: "https://flagcdn.com/w320/mv.png",
      code: "+960",
    },
    {
      value: "Malí",
      label: "Malí",
      image: "https://flagcdn.com/w320/ml.png",
      code: "+223",
    },
    {
      value: "Malta",
      label: "Malta",
      image: "https://flagcdn.com/w320/mt.png",
      code: "+356",
    },
    {
      value: "Islas Marshall",
      label: "Islas Marshall",
      image: "https://flagcdn.com/w320/mh.png",
      code: "+692",
    },
    {
      value: "Martinica",
      label: "Martinica",
      image: "https://flagcdn.com/w320/mq.png",
      code: "+596",
    },
    {
      value: "Mauritania",
      label: "Mauritania",
      image: "https://flagcdn.com/w320/mr.png",
      code: "+222",
    },
    {
      value: "Islas Mauricio",
      label: "Islas Mauricio",
      image: "https://flagcdn.com/w320/mu.png",
      code: "+230",
    },
    {
      value: "México",
      label: "México",
      image: "https://flagcdn.com/w320/mx.png",
      code: "+52",
    },
    {
      value: "Micronesia",
      label: "Micronesia",
      image: "https://flagcdn.com/w320/fm.png",
      code: "+691",
    },
    {
      value: "Moldova",
      label: "Moldova",
      image: "https://flagcdn.com/w320/md.png",
      code: "+373",
    },
    {
      value: "Mónaco",
      label: "Mónaco",
      image: "https://flagcdn.com/w320/mc.png",
      code: "+377",
    },
    {
      value: "Mongolia",
      label: "Mongolia",
      image: "https://flagcdn.com/w320/mn.png",
      code: "+976",
    },
    {
      value: "Montenegro",
      label: "Montenegro",
      image: "https://flagcdn.com/w320/me.png",
      code: "+382",
    },
    {
      value: "Montserrat",
      label: "Montserrat",
      image: "https://flagcdn.com/w320/ms.png",
      code: "+1 664",
    },
    {
      value: "Marruecos",
      label: "Marruecos",
      image: "https://flagcdn.com/w320/ma.png",
      code: "+212",
    },
    {
      value: "Mozambique",
      label: "Mozambique",
      image: "https://flagcdn.com/w320/mz.png",
      code: "+258",
    },
    {
      value: "Myanmar (Birmania)",
      label: "Myanmar (Birmania)",
      image: "https://flagcdn.com/w320/mm.png",
      code: "+95",
    },
    {
      value: "Namibia",
      label: "Namibia",
      image: "https://flagcdn.com/w320/na.png",
      code: "+264",
    },
    {
      value: "Nauru",
      label: "Nauru",
      image: "https://flagcdn.com/w320/nr.png",
      code: "+674",
    },
    {
      value: "Nepal",
      label: "Nepal",
      image: "https://flagcdn.com/w320/np.png",
      code: "+977",
    },
    {
      value: "Holanda",
      label: "Holanda",
      image: "https://flagcdn.com/w320/hn.png",
      code: "+31",
    },
    {
      value: "Nueva Caledonia",
      label: "Nueva Caledonia",
      image: "https://flagcdn.com/w320/nc.png",
      code: "+687",
    },
    {
      value: "Nueva Zelanda",
      label: "Nueva Zelanda",
      image: "https://flagcdn.com/w320/nz.png",
      code: "+64",
    },
    {
      value: "Nicaragua",
      label: "Nicaragua",
      image: "https://flagcdn.com/w320/ni.png",
      code: "+505",
    },
    {
      value: "Níger",
      label: "Níger",
      image: "https://flagcdn.com/w320/ne.png",
      code: "+227",
    },
    {
      value: "Nigeria",
      label: "Nigeria",
      image: "https://flagcdn.com/w320/ng.png",
      code: "+234",
    },
    {
      value: "Islas Marianas del Norte",
      label: "Islas Marianas del Norte",
      image: "https://flagcdn.com/w320/mp.png",
      code: "+1 670",
    },
    {
      value: "Noruega",
      label: "Noruega",
      image: "https://flagcdn.com/w320/no.png",
      code: "+47",
    },
    {
      value: "Omán",
      label: "Omán",
      image: "https://flagcdn.com/w320/om.png",
      code: "+968",
    },
    {
      value: "Pakistán",
      label: "Pakistán",
      image: "https://flagcdn.com/w320/pk.png",
      code: "+92",
    },
    {
      value: "Palaos",
      label: "Palaos",
      image: "https://flagcdn.com/w320/pw.png",
      code: "+680",
    },
    {
      value: "Autoridad Palestina",
      label: "Autoridad Palestina",
      image: "https://flagcdn.com/w320/ps.png",
      code: "+970",
    },
    {
      value: "Panamá",
      label: "Panamá",
      image: "https://flagcdn.com/w320/pa.png",
      code: "+507",
    },
    {
      value: "Papúa Nueva Guinea",
      label: "Papúa Nueva Guinea",
      image: "https://flagcdn.com/w320/pg.png",
      code: "+675",
    },
    {
      value: "Paraguay",
      label: "Paraguay",
      image: "https://flagcdn.com/w320/py.png",
      code: "+595",
    },
    {
      value: "Perú",
      label: "Perú",
      image: "https://flagcdn.com/w320/pe.png",
      code: "+51",
    },
    {
      value: "Filipinas",
      label: "Filipinas",
      image: "https://flagcdn.com/w320/ph.png",
      code: "+63",
    },
    {
      value: "Polonia",
      label: "Polonia",
      image: "https://flagcdn.com/w320/pl.png",
      code: "+48",
    },
    {
      value: "Portugal",
      label: "Portugal",
      image: "https://flagcdn.com/w320/pt.png",
      code: "+351",
    },
    {
      value: "Qatar",
      label: "Qatar",
      image: "https://flagcdn.com/w320/qa.png",
      code: "+974",
    },
    {
      value: "Reunión",
      label: "Reunión",
      image: "https://flagcdn.com/w320/re.png",
      code: "+262",
    },
    {
      value: "Rumania",
      label: "Rumania",
      image: "https://flagcdn.com/w320/ro.png",
      code: "+40",
    },
    {
      value: "Rusia",
      label: "Rusia",
      image: "https://flagcdn.com/w320/ru.png",
      code: "+7",
    },
    {
      value: "Ruanda",
      label: "Ruanda",
      image: "https://flagcdn.com/w320/rw.png",
      code: "+250",
    },
    {
      value: "Samoa",
      label: "Samoa",
      image: "https://flagcdn.com/w320/ws.png",
      code: "+685",
    },
    {
      value: "San Marino",
      label: "San Marino",
      image: "https://flagcdn.com/w320/sm.png",
      code: "+378",
    },
    {
      value: "Santo Tomé/Príncipe",
      label: "Santo Tomé/Príncipe",
      image: "https://flagcdn.com/w320/st.png",
      code: "+239",
    },
    {
      value: "Arabia Saudita",
      label: "Arabia Saudita",
      image: "https://flagcdn.com/w320/sa.png",
      code: "+966",
    },
    {
      value: "Senegal",
      label: "Senegal",
      image: "https://flagcdn.com/w320/sn.png",
      code: "+221",
    },
    {
      value: "Serbia",
      label: "Serbia",
      image: "https://flagcdn.com/w320/rs.png",
      code: "+381",
    },
    {
      value: "Seychelles",
      label: "Seychelles",
      image: "https://flagcdn.com/w320/sc.png",
      code: "+248",
    },
    {
      value: "Sierra Leona",
      label: "Sierra Leona",
      image: "https://flagcdn.com/w320/sl.png",
      code: "+232",
    },
    {
      value: "Singapur",
      label: "Singapur",
      image: "https://flagcdn.com/w320/sg.png",
      code: "+65",
    },
    {
      value: "Isla de San Martín",
      label: "Isla de San Martín",
      image: "https://flagcdn.com/w320/sx.png",
      code: "+1 721",
    },
    {
      value: "Eslovaquia",
      label: "Eslovaquia",
      image: "https://flagcdn.com/w320/sk.png",
      code: "+421",
    },
    {
      value: "Islas Salomón",
      label: "Islas Salomón",
      image: "https://flagcdn.com/w320/sb.png",
      code: "+677",
    },
    {
      value: "Sudáfrica",
      label: "Sudáfrica",
      image: "https://flagcdn.com/w320/za.png",
      code: "+27",
    },
    {
      value: "Sudán Meridional",
      label: "Sudán Meridional",
      image: "https://flagcdn.com/w320/sd.png",
      code: "+211",
    },
    {
      value: "España",
      label: "España",
      image: "https://flagcdn.com/w320/es.png",
      code: "+34",
    },
    {
      value: "Sri Lanka",
      label: "Sri Lanka",
      image: "https://flagcdn.com/w320/lk.png",
      code: "+94",
    },
    {
      value: "San Pedro/Miquelón",
      label: "San Pedro/Miquelón",
      image: "https://flagcdn.com/w320/pm.png",
      code: "+508",
    },
    {
      value: "San Cristóbal/Nieves",
      label: "San Cristóbal/Nieves",
      image: "https://flagcdn.com/w320/kn.png",
      code: "+1 869",
    },
    {
      value: "Santa Lucía",
      label: "Santa Lucía",
      image: "https://flagcdn.com/w320/lc.png",
      code: "+1 758",
    },
    {
      value: "San Vicente/Granadinas",
      label: "San Vicente/Granadinas",
      image: "https://flagcdn.com/w320/vc.png",
      code: "+1 784",
    },
    {
      value: "Sudán",
      label: "Sudán",
      image: "https://flagcdn.com/w320/sd.png",
      code: "+249",
    },
    {
      value: "Suriname",
      label: "Suriname",
      image: "https://flagcdn.com/w320/sr.png",
      code: "+597",
    },
    {
      value: "Suazilandia",
      label: "Suazilandia",
      image: "https://flagcdn.com/w320/sz.png",
      code: "+268",
    },
    {
      value: "Suecia",
      label: "Suecia",
      image: "https://flagcdn.com/w320/se.png",
      code: "+46",
    },
    {
      value: "Suiza",
      label: "Suiza",
      image: "https://flagcdn.com/w320/ch.png",
      code: "+41",
    },
    {
      value: "Siria",
      label: "Siria",
      image: "https://flagcdn.com/w320/sy.png",
      code: "+963",
    },
    {
      value: "Taiwán",
      label: "Taiwán",
      image: "https://flagcdn.com/w320/tw.png",
      code: "+886",
    },
    {
      value: "Tayikistán",
      label: "Tayikistán",
      image: "https://flagcdn.com/w320/tj.png",
      code: "+992",
    },
    {
      value: "Tanzania",
      label: "Tanzania",
      image: "https://flagcdn.com/w320/tz.png",
      code: "+255",
    },
    {
      value: "Tailandia",
      label: "Tailandia",
      image: "https://flagcdn.com/w320/th.png",
      code: "+66",
    },
    {
      value: "Togo",
      label: "Togo",
      image: "https://flagcdn.com/w320/tg.png",
      code: "+228",
    },
    {
      value: "Tokelau",
      label: "Tokelau",
      image: "https://flagcdn.com/w320/tk.png",
      code: "+690",
    },
    {
      value: "Tonga",
      label: "Tonga",
      image: "https://flagcdn.com/w320/to.png",
      code: "+676",
    },
    {
      value: "Trinidad y Tobago",
      label: "Trinidad y Tobago",
      image: "https://flagcdn.com/w320/tt.png",
      code: "+1 (868)",
    },
    {
      value: "Túnez",
      label: "Túnez",
      image: "https://flagcdn.com/w320/tn.png",
      code: "+216",
    },
    {
      value: "Turquía",
      label: "Turquía",
      image: "https://flagcdn.com/w320/tr.png",
      code: "+90",
    },
    {
      value: "Turkmenistán",
      label: "Turkmenistán",
      image: "https://flagcdn.com/w320/tm.png",
      code: "+993",
    },
    {
      value: "Islas Turcas y Caicos",
      label: "Islas Turcas y Caicos",
      image: "https://flagcdn.com/w320/tc.png",
      code: "+1 649",
    },
    {
      value: "Tuvalu",
      label: "Tuvalu",
      image: "https://flagcdn.com/w320/tv.png",
      code: "+688",
    },
    {
      value: "Uganda",
      label: "Uganda",
      image: "https://flagcdn.com/w320/ug.png",
      code: "+256",
    },
    {
      value: "Ucrania",
      label: "Ucrania",
      image: "https://flagcdn.com/w320/ua.png",
      code: "+380",
    },
    {
      value: "Emiratos Árabes Unidos	",
      label: "Emiratos Árabes Unidos	",
      image: "https://flagcdn.com/w320/ae.png",
      code: "+971",
    },
    {
      value: "Reino Unido",
      label: "Reino Unido",
      image: "https://flagcdn.com/w320/gb.png",
      code: "+44",
    },
    {
      value: "Uruguay",
      label: "Uruguay",
      image: "https://flagcdn.com/w320/uy.png",
      code: "+598",
    },
    {
      value: "Uzbekistán",
      label: "Uzbekistán",
      image: "https://flagcdn.com/w320/uz.png",
      code: "+998",
    },
    {
      value: "Vanuatu",
      label: "Vanuatu",
      image: "https://flagcdn.com/w320/vu.png",
      code: "+678",
    },
    {
      value: "Venezuela",
      label: "Venezuela",
      image: "https://flagcdn.com/w320/ve.png",
      code: "+58",
    },
    {
      value: "Vietnam",
      label: "Vietnam",
      image: "https://flagcdn.com/w320/vn.png",
      code: "+84",
    },
    {
      value: "Yemen",
      label: "Yemen",
      image: "https://flagcdn.com/w320/ye.png",
      code: "+967",
    },
    {
      value: "Zambia",
      label: "Zambia",
      code: "+260",
      image: "https://flagcdn.com/w320/zm.png",
    },
    {
      value: "Zimbabue",
      label: "Zimbabue",
      code: "+263",
      image: "https://flagcdn.com/w320/zw.png",
    },
  ]

  const numberWorkers = ["1-4", "5-10", "11-20", "21-30", "40 a más agentes"]

  const numberClient = ["0-500", "500-2000", "Más de 2000"]

  //Estado para abrir el modal de confirmacion
  const [confirm, setConfirm] = useState(false)
  const onClickConfirm = () => {
    setConfirm(true)

    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
  }

  return (
    <div className="fnd">
      <section>
        <BannerCookies />
        <div className="banner-invisible-ebook" />
        <div>
          <Header
            path="/"
            location={location}
            bannerCTA={true}
            windowsWidth={windowsWidth}
          />
        </div>
        <section className="container">
          {confirm ? (
            <div>
              <TemplatePagesThank location={location} text="En unos momentos un especialista de soluciones se comunicará contigo para atender tu solicitud."/>
            </div>
          ) : (
            <div>
              <Intro
                onClickConfirm={onClickConfirm}
                countries={countries}
                numberWorkers={numberWorkers}
                numberClient={numberClient}
              />
            </div>
          )}

          <Footer data={dataOne.footer} location={location} />
          <FooterMobile data={dataOne.footer} location={location} />
        </section>
      </section>
    </div>
  )
}

export default TemplatePageHablemos
