import score from "@components/pageHablemos/imgDemoandHablemos/product/score.svg"
import ticker from "@components/pageHablemos/imgDemoandHablemos/product/ticker.svg"
import platcon from "@components/pageHablemos/imgDemoandHablemos/product/platcon.svg"
import whatsapp from "@components/pageHablemos/imgDemoandHablemos/solutions/whatsapp.svg"
import chatbots from "@components/pageHablemos/imgDemoandHablemos/solutions/chatbots.svg"
import envio from "@components/pageHablemos/imgDemoandHablemos/solutions/envios.svg"
import marcadores from "@components/pageHablemos/imgDemoandHablemos/solutions/marcadores.svg"
import voice from "@components/pageHablemos/imgDemoandHablemos/solutions/voice.svg"
import siptrunk from "@components/pageHablemos/imgDemoandHablemos/solutions/siptrunk.svg"
import voz from "@components/pageHablemos/imgDemoandHablemos/channels/voz.svg"
import channelWhatsapp from "@components/pageHablemos/imgDemoandHablemos/channels/whatsapp.svg"
import messenger from "@components/pageHablemos/imgDemoandHablemos/channels/messenger.svg"
import instagram from "@components/pageHablemos/imgDemoandHablemos/channels/instagram.svg"
import webchat from "@components/pageHablemos/imgDemoandHablemos/channels/webchat.svg"
import correo from "@components/pageHablemos/imgDemoandHablemos/channels/correo.svg"
import sms from "@components/pageHablemos/imgDemoandHablemos/channels/sms.svg"
import atencion from "@components/pageHablemos/imgDemoandHablemos/operations/atencion.svg"
import marketing from "@components/pageHablemos/imgDemoandHablemos/operations/marketing.svg"
import ventas from "@components/pageHablemos/imgDemoandHablemos/operations/ventas.svg"
import cobranzas from "@components/pageHablemos/imgDemoandHablemos/operations/cobranzas.svg"
import manual from "@components/pageHablemos/imgDemoandHablemos/marcadores/manual.svg"
import progresivo from "@components/pageHablemos/imgDemoandHablemos/marcadores/progresivo.svg"
import predictivo from "@components/pageHablemos/imgDemoandHablemos/marcadores/predictivo.svg"
import consulta from "@components/pageHablemos/imgDemoandHablemos/marcadores/consulta.svg"

// Los "id" deben corresponder a los valores de "solucion" en Hubspot, para crear un nuevo valor, se debe solicitar el ID al administrador de Hubspot
export const optionsProduct = [
  {
    id: "score_v2",
    title: "Contact Center",
    image: score,
    description: "Contact Center omnicanal en la nube",
    type: "product",
  },
  {
    id: "ticker",
    title: "Conversations",
    image: ticker,
    description: "Atención al cliente omnicanal y chatbots",
    type: "product",
  },
  {
    id: "platcon",
    title: "Validation",
    image: platcon,
    description: "Validación de teléfonos",
    type: "product",
  },
  {
    id: "whatsapp_business_api",
    image: whatsapp,
    description: "WhatsApp Business API",
  },
  {
    id: "chatbots",
    image: chatbots,
    description: "Chatbots",
  },
  {
    id: "whatsapp_broadcast",
    image: envio,
    description: "Envíos masivos de WhatsApp",
  },
  {
    id: "marcador_predictivo",
    image: marcadores,
    description: "Marcador progresivo/predictivo",
  },
  {
    id: "sip_trunk",
    image: siptrunk,
    description: "SIP Trunk",
  },
  {
    id: "voice_api",
    image: voice,
    description: "Voice API",
  },
]
// Los "id" deben corresponder a los valores de "solucion" en Hubspot, para crear un nuevo valor, se debe solicitar el ID al administrador de Hubspot

export const optionsChannels = [
  {
    id: "voz",
    image: voz,
    description: "Voz",
  },
  {
    id: "whatsapp",
    image: channelWhatsapp,
    description: "WhatsApp Business",
  },
  {
    id: "messenger",
    image: messenger,
    description: "Facebook Messenger",
  },
  {
    id: "instagram",
    image: instagram,
    description: "Instagram",
  },
  {
    id: "webchat",
    image: webchat,
    description: "Webchat",
  },
  {
    id: "correo",
    image: correo,
    description: "Correo electrónico",
  },
  {
    id: "sms",
    image: sms,
    description: "SMS",
  },
]

export const optionsOperations1 = [
  {
    id: "atencion_al_cliente",
    image: atencion,
    description: "Atención al cliente",
  },
  {
    id: "telemarketing",
    image: marketing,
    description: "Marketing",
  },
  {
    id: "televentas",
    image: ventas,
    description: "Ventas",
  },
  {
    id: "cobranzas",
    image: cobranzas,
    description: "Cobranzas",
  },
]

export const optionsOperations2 = [
  {
    id: "atencion_al_cliente",
    image: atencion,
    description: "Atención al cliente",
  },
  {
    id: "telemarketing",
    image: marketing,
    description: "Telemarketing",
  },
  {
    id: "televentas",
    image: ventas,
    description: "Televentas",
  },
  {
    id: "cobranzas",
    image: cobranzas,
    description: "Cobranzas",
  },
]

export const optionsMarcadores = [
  {
    id: "marcacion-manual",
    image: manual,
    description: "Marcación manual",
  },
  {
    id: "marcacion-progresiva",
    image: progresivo,
    description: "Marcación progresiva",
  },
  {
    id: "marcacion-predictiva",
    image: predictivo,
    description: "Marcación predictiva",
  },
  {
    id: "por-decidir",
    image: consulta,
    description: "Aun voy a decidirlo",
  },
]
